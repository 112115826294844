<template>
	<div>
		<div class="row row-filter">
			<div class="columns column3 align-left">
				<h3>{{ header }}</h3>
				<div v-parse-links v-html="content" />
				<div class="filter-buttons-bottom">
					<nuxt-link class="text-button" :to="localePath('rooms')">
						<span>{{ $t('allRooms') }}</span>
						<font-awesome-icon :icon="`fal fa-chevron-right`" size="1x" />
					</nuxt-link>
				</div>
			</div>
			<div class="columns column9">
				<bc-carousel
					ref="roomsCarousel"
					class="room-slider"
					:mouse-drag="false"
					:breakpoints="breakpoints"
					:model-value="1"
				>
					<template #addons>
						<bc-navigation class="slider-navigation">
							<template #next>
								<div class="slider-button slider-next" />
							</template>
							<template #prev>
								<div class="slider-button slider-prev" />
							</template>
						</bc-navigation>
					</template>
					<bc-slide v-for="item in items" :key="item.ID" class="room-item slider-item active">
						<div class="image-holder">
							<nuxt-link :to="localePath(`/${item.filename}`)">
								<div class="image">
									<picture>
										<source :srcset="item.indexImageWebp" type="image/webp" />
										<source :srcset="item.indexImage" />
										<img :src="item.indexImage" :alt="item.indexImageAlt" loading="lazy" />
									</picture>
								</div>
								<div class="content">
									<p>{{ item.indexSubtitle }}</p>
									<h4>{{ item.header }}</h4>
								</div>
							</nuxt-link>
						</div>
					</bc-slide>
				</bc-carousel>
			</div>
		</div>
	</div>
</template>

<script setup>
defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
	items: { type: Array, default: () => [] },
});

const roomsCarousel = ref(null);

const breakpoints = {
	700: {
		itemsToShow: 2,
	},
	1080: {
		itemsToShow: 3.5,
	},
};
</script>

<style lang="scss" scoped>
.room-section {
	.row-filter {
		flex-flow: row wrap;
		align-items: flex-start;
	}

	.column9 {
		padding-left: 50px;
	}

	.column3 {
		padding-right: 60px;
		padding-bottom: 300px;
		position: relative;
		border-right: 1px solid #3c3834;

		.filter-buttons-bottom {
			position: absolute;
			bottom: 0;
			left: 20px;
		}

		a {
			margin: 60px 0 0;
		}
	}

	h3 {
		font-family: var(--body-font-family);
		margin: 0 0 20px;
		max-width: 300px;
		color: var(--header-color-secondary);
	}

	a {
		color: var(--body-color);
		text-decoration: none;
		text-transform: uppercase;
	}

	.text-button {
		background: none;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 5px;

		svg {
			font-size: 18px;
			margin: 1px 0 -1px 15px;
		}
	}
}

.room-slider {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: stretch;
	overflow: hidden;
	position: relative;
	padding: 0 0 100px;

	.content {
		text-align: left;
		padding: 10px 0;
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__viewport) {
	width: 100%;
}

:deep(.slider-navigation) {
	position: absolute;
	bottom: 0;
	left: 0;
	top: unset;
	transform: unset;
	width: 67px;
	height: 67px;
	margin: 0;
	padding: 0;

	&.carousel__next {
		left: unset;
		right: 0;
	}
}
/* stylelint-enable selector-class-pattern */

.image-holder {
	position: relative;
	padding: 0;
	width: 100%;

	a {
		z-index: 1;
		position: relative;
		text-decoration: none;

		&::after {
			display: none;
		}
	}
}

.slider-item {
	display: none;
	width: 30%;
	padding: 0 3% 0 0;
	flex-flow: column wrap;
	position: relative;
	justify-content: flex-start;
	align-items: flex-start;

	.maps-link {
		background: url('~/assets/images/rooms-maps-pointer.png') no-repeat center center;
		width: 55px;
		height: 55px;
		background-size: 55px;
		cursor: pointer;
		position: absolute;
		bottom: 80px;
		left: 0;
		z-index: 2;
	}

	&.active {
		display: flex;
	}

	.image {
		width: 100%;

		img {
			clip-path: polygon(50% 13%, 100% 30%, 100% 100%, 0% 100%, 0% 30%);
			overflow: hidden;
		}
	}

	h4 {
		font-family: var(--body-font-family);
		margin: 0;
		color: var(--body-color);
		font-weight: 800;
	}

	p {
		font-family: var(--body-font-family);
		color: var(--body-color);
		margin: 0;
		font-weight: 500;
		text-transform: none;
	}
}

@supports (-webkit-mask-image: url('#mask')) or (mask-image: url('#mask')) {
	.slider-item {
		.image {
			img {
				clip-path: none;
				mask-image: url('~/assets/images/rooms-shape-mask.png');
				mask-size: 100%;
			}
		}
	}
}

@media (max-width: 1080px) {
	.room-section {
		.column3 {
			width: 100%;
			text-align: center;
			padding: 0 20px;
			max-width: 600px;
			margin: 0 auto;
			border: none;

			.filter-buttons-bottom {
				position: relative;
				inset: unset;
				margin: 0 0 50px;
				padding: 0 0 60px;
				display: flex;
			}

			.text-button {
				position: relative;
				left: auto !important;
				right: auto;
				text-align: center;
				margin: 0 auto;
				bottom: auto;
				justify-content: center;
				overflow: hidden;
			}
		}

		h3 {
			margin: 0 auto 20px;
		}
	}
}

@media (max-width: 880px) {
	.room-section {
		.column3 {
			.filter-buttons-bottom {
				padding: 0 0 60px;
			}
		}
	}
}

@media (max-width: 700px) {
	.room-slider {
		padding: 0 0 70px;
	}

	.slider-item {
		margin: 0;
	}

	.image-holder {
		padding: 0 15px;
	}

	.room-section {
		.column9 {
			border: none;
			padding-left: 20px;
		}
	}
}
</style>
