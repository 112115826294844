<template>
	<section class="magazine-section">
		<div class="row row-header">
			<div class="columns column12">
				<h2>Vondel Magazine</h2>
			</div>
		</div>
		<div class="row">
			<div class="border" />
		</div>
		<div class="row magazine-row">
			<div v-for="item in items" :key="item.ID" class="magazine-item columns column4">
				<a
					:href="config.public.siteUrlGroup + localePath(`/${item.filename}`)"
					target="_blank"
					class="image-link no-text-decoration"
				>
					<picture>
						<source :srcset="item.overviewImageWebp" type="image/webp" />
						<source :srcset="item.overviewImage" />
						<img :src="item.overviewImage" :alt="item.overviewImageAlt" loading="lazy" />
					</picture>
				</a>
				<div class="content">
					<strong v-if="item.category" class="tag">{{ item.category }}</strong>
					<h3>
						<a :href="config.public.siteUrlGroup + localePath(`/${item.filename}`)" target="_blank">
							{{ item.header }}
						</a>
					</h3>
					<div v-parse-links v-html="item.overviewContent" />
					<a
						class="text-button"
						:href="config.public.siteUrlGroup + localePath(`/${item.filename}`)"
						target="_blank"
					>
						<span>{{ $t('readMore') }}</span>
						<font-awesome-icon :icon="`fal fa-chevron-right`" size="1x" />
					</a>
				</div>
			</div>
		</div>
		<div class="row align-center button-row">
			<div class="columns column12">
				<a class="button no-fill" :href="config.public.siteUrlGroup + groupPage.filename" target="_blank">
					{{ $t('moreArticles') }}
				</a>
			</div>
		</div>
	</section>
</template>

<script setup>
const config = useRuntimeConfig();

defineProps({
	items: { type: Array, default: () => [] },
	groupPage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
.border {
	background: #3c3834;
	opacity: 1;
	height: 1px;
	width: calc(100% - 40px);
	margin: 40px auto 60px;
}

.tag {
	display: inline-block;
	width: 100%;
	text-transform: uppercase;
	margin: 0 0 20px;
}

.magazine-section {
	background: var(--intro-block-background-color);

	h2 {
		color: var(--header-color);
	}
}

.row-header {
	max-width: 460px;
}

.button-row {
	margin: 100px auto 0;
}

.magazine-row {
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: stretch;

	.text-button {
		background: none;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 5px;

		svg {
			font-size: 18px;
			margin: 1px 0 -1px 15px;
		}
	}

	.magazine-item {
		width: calc((100% / 3) + 20px);
		border-right: 1px solid #3c3834;
		padding: 0 60px;
		position: relative;

		&:first-child {
			padding: 0 60px 0 20px;
			width: calc((100% / 3) - 20px);

			.content {
				.text-button {
					left: 20px;
				}
			}

			.image-link {
				&:hover img {
					transform: translate(-24px, 0);
				}
			}
		}

		&:last-child {
			border: none;
			padding: 0 20px 0 60px;
			width: calc((100% / 3) - 20px);

			.image-link {
				&:hover img {
					transform: rotate(6deg);
				}
			}
		}

		.image-link {
			overflow: visible;

			img {
				transition: all 0.3s ease-in-out;
				aspect-ratio: 5 / 6;
			}

			&:hover img {
				transform: scale(1.07);
			}
		}
	}

	.content {
		padding: 50px 0;

		.text-button {
			position: absolute;
			bottom: 0;
			left: 60px;
		}
	}

	:deep(p) {
		margin: 20px 0 50px;
	}

	h3 {
		font-size: var(--h4-font-size);
		max-width: 400px;
		margin: 0 0 10px;

		a {
			text-decoration: none;
			color: var(--header-color-secondary);

			&::after {
				display: none;
			}
		}
	}
}

@media (max-width: 880px) {
	.magazine-row {
		flex-flow: column wrap;

		.magazine-item {
			width: calc(100% - 40px) !important;
			border: none !important;
			padding: 0 20px !important;
			margin: 0 auto;

			.content {
				.text-button {
					left: 20px !important;
					bottom: 40px;
					justify-content: flex-start;
				}
			}
		}
	}
}
</style>
